<style lang="scss" scoped>
.page-order-line {
    .c-datetime-range {
        max-width: 256px !important;
    }
    .map-container  {
        position: relative;
        border: 1px solid #eee;
        padding: 0px;
        margin: 0px;
        width: 100%;
        height: 650px;
    }
    .map-screen {
        margin: 0;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 99998;
    }
    .total-count {
        display: block;
        position: absolute;
        top: 5px;
        right: 40px;
        z-index: 10;
        background-color: #ffffff;
        padding: 6px 12px;
        border-radius: 2px;
        box-shadow: 0 0 3px rgba(0,0,0,.5);
    }
    .full-screen, .order-mousetools {
        display: block;
        position: absolute;
        top: 5px;
        right: 5px;
        z-index: 10;
        background-color: #ffffff;
        padding: 2px;
        border-radius: 2px;
        box-shadow: 0 0 3px rgba(0,0,0,.5);
        cursor: pointer;
        .el-icon-full-screen {
            font-size: 26px;
        }
    }
    .full-screen:hover, .order-mousetools .btn:hover {
        background-color: #f0f0f0;
    }
    .order-mousetools {
        top: 42px;
        right: 5px;
        padding: 0;
        .btn {
            display: block;
            float: right;
            padding: 4px;
            i {
                font-size: 22px;
            }
        }
        .btn-open, .btn-open:hover {
            background: rgba(121, 184, 255, 1);
            i {
                color: #ffffff;
            }
        }
        .btn-actived {
            i {
                color: #79b8ff;
            }
        }
    }
}
</style>

<template>
    <div class="page-order-line">
        <div class="toolbars mrgb10">
            <div class="buttons mrgb5">
                <!--<div class="fl">
                    <el-button-group>
                        <el-button type="primary" :plain="listQuery.timeType !== 0" size="medium" @click="changeTimeType(0)">用车</el-button>
                        <el-button type="warning" :plain="listQuery.timeType === 0" size="medium" @click="changeTimeType(1)">还车</el-button>
                    </el-button-group>
                </div>-->
                <div class="fl">
                    <el-date-picker class="c-datetime-range mrgr5 mrgb5" v-model="selectedDate" type="daterange" size="medium"
                        :picker-options="pickerOptions" :clearable="false"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期"
                        align="right">
                    </el-date-picker>
                    <el-time-picker class="c-datetime-range mrgr5 mrgb5" is-range v-model="selectedTime" size="medium" @change="changeTime"
                        range-separator="至" :default-value="['1970-01-01 00:00:00', '1970-01-01 23:59:59']"
                        start-placeholder="开始时间"
                        end-placeholder="结束时间"
                        placeholder="选择时间范围">
                    </el-time-picker>
                    <el-input class="c-el-input mrgr5 mrgb5" placeholder="输入关键字查询" clearable v-model="listQuery.keywords"
                        size="medium"></el-input>
                    <el-button type="primary" size="medium" @click="_search()"><i class="fa fa-search"></i> 查询</el-button>
                    <el-tooltip effect="dark" placement="top">
                        <div slot="content">
                            注：每日7:30以后更新数据。<br />
                            拖动时间可以快捷查看该时间段的数据。<br />
                            请不要查询太长时间段的数据，否则数据量太大，地图会卡顿明显。<br />
                            订单轨迹：以关锁还车时间统计，按弧线顺时针方向旋转，起点表示从该点位车辆骑出，终点表示车辆骑入到该点位。<br />
                        </div>
                        <el-tag class="mrgl5" type="warning" size="medium"><i class="el-icon-view f12"></i> 查看说明</el-tag>
                    </el-tooltip>
                </div>
                <div class="fr">
                    <span class="col_danger">
                        <i class="el-icon-warning"></i>
                        每日7:30以后更新数据
                    </span>
                </div>
                <div class="clearb"></div>
                <div>
                    <el-button-group>
                        <el-button type="primary" :plain="!(cueSelectTimers.length === 2 && time >= cueSelectTimers[0] && time <= cueSelectTimers[1])" size="medium"
                            v-for="(item, time) in 24" :key="item" @click="selectTime(time)"
                            @mousedown.native="mouseSelectTime(time, 0)" @mouseup.native="mouseSelectTime(time, 1)">{{ time }}时</el-button>
                    </el-button-group>
                </div>
            </div>
        </div>
        <div class="map-container" :class="isScreen ? 'map-screen' : ''" id='container'>
            <span class="total-count col_primary" title="当前查询出的总车辆数">总数量：{{ orderJson.length }}</span>
            <span class="full-screen" :title="!isScreen ? '全屏' : '还原'" @click="isScreen = !isScreen">
                <i class="el-icon-full-screen"></i>
            </span>
            <span class="order-mousetools">
                <el-tooltip :content="isChooseOpen ? '结束框选' : '开始框选'" placement="top-end">
                    <span class="btn" :class="isChooseOpen ? 'btn-open' : ''"
                        @click="chooseSwitch()"><i :class="isChooseOpen ? 'el-icon-crop' : 'el-icon-crop'"></i></span>
                </el-tooltip>
                <template v-if="isChooseOpen">
                    <el-tooltip content="矩形框选" placement="top-end">
                        <span class="btn" :class="chooseType === chooseTypeArray[0] ? 'btn-actived' : ''"
                            @click="chooseSelectType(chooseTypeArray[0])"><i class="el-icon-menu"></i></span>
                    </el-tooltip>
                    <el-tooltip content="圆形框选" placement="top-end">
                        <span class="btn" :class="chooseType === chooseTypeArray[1] ? 'btn-actived' : ''"
                            @click="chooseSelectType(chooseTypeArray[1])"><i class="el-icon-bangzhu"></i></span>
                    </el-tooltip>
                </template>
            </span>
        </div>
    </div>
</template>

<script>
import * as funReport from "@/api/report"
import moment from 'moment'

let dataVars = {
    map: null, // 地图
    polygonMap: null,
    labelsLayer: null,
    parkingMarker: [],
    orderLines: [],
    mouseTool: null, // 鼠标绘制工具
    polygonObjs: [], // 绘制选框map对象
    textMarkers: [],
    parkTargets: [] // 点击站点map对象
}
export default {
    name: "pageOrderLine",
    data() {
        let that = this
        return {
            pageHeader: {
                desc: "订单轨迹，以还车时间统计的订单数据。"
            },
            listQuery: {
                area: "",
                timeType: 1,
                beginDate: "",
                endDate: "",
                beginTime: "",
                endTime: "",
                keywords: ""
            },
            orderJson: [],
            cueSelectTimers: [],
            curMouseDownTime: null,
            isScreen: false,
            selectedDate: [moment().subtract(1, "d").format("YYYY-MM-DD"), moment().subtract(1, "d").format("YYYY-MM-DD")],
            selectedTime: [],
            pickerOptions: {
                shortcuts: [
                    {
                        text: "昨天",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "days")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一周",
                        onClick: (picker) => {
                            let timeArr = that.setPickerTime(picker, 1, "weeks")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近三月",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 3, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近半年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 6, "months")
                            that.setQueryTime(timeArr)
                        }
                    },
                    {
                        text: "近一年",
                        onClick(picker) {
                            let timeArr = that.setPickerTime(picker, 1, "years")
                            that.setQueryTime(timeArr)
                        }
                    }
                ]
            },
            coverMarker: null,
            coverPaths: [], // 服务区多边形点位
            parkings: [],
            isShowParking: true,
            isChooseOpen: false, // 框选开关
            chooseTypeArray: [1, 2],
            chooseType: null,
            iconImages: ["/static/images/map/mass2.png", "/static/images/map/mass1.png", "/static/images/map/poi-marker-default.png"],
            fillColors: ["#1791fc", "#05ad80", "#9907f1", "#e0992d", "#2de0bc"], // 用于随机取色
            lineColors: ["#f78989", "#67c23a", "#54a6fe"],
            switchObjStates: {}, // 选框切换显示隐藏轨迹
            isMapEditState: false,
            isShowAllLine: true, // 全部显示或隐藏轨迹
            isNeedAutoSwitch: false, // 是否需要自动切换
        }
    },
    watch: {
        "$store.getters.serviceArea"(v) {
            this.mxIsSameActiveTabWithRoute(() => {
                this.getCurrentAreaData()
                this.setPolygonContains()
                this.initLoadData()
            })
        }
    },
    async mounted() {
        this.setSysPageHeader(this.pageHeader)
        this.getCurrentAreaData()
        await this.initMap()
        await this.initLoadData()
        this.loadKeyDown()
        this.loadKeyUp()
    },
    unmounted() {
        dataVars.map?.destroy()
        document.removeEventListener("keydown")
        document.removeEventListener("keyup")
    },
    methods: {
        // 重写本页的时间范围的快捷筛选，设置时间
        setPickerTime(picker, spaceTime, dateStr, isStartOf) {
            let timeArr = []
            let startTime = null
            let endTime = moment().subtract(1, "d").format("YYYY-MM-DD 23:59:59")
            startTime = moment().subtract(spaceTime, dateStr).format("YYYY-MM-DD 00:00:00")
            startTime = moment(startTime).format("YYYY-MM-DD 00:00:00")
            timeArr = [startTime, endTime]
            if (picker) {
                picker.$emit('pick', timeArr);
            }
            return timeArr
        },
        setQueryTime(timeArr) {
            this.listQuery.beginDate = moment(timeArr[0]).format("YYYY-MM-DD")
            this.listQuery.endDate = moment(timeArr[1]).format("YYYY-MM-DD")
        },
        setSelectedTime() {
            let psDate = ""
            let psTime = ""
            let peDate = ""
            let peTime = ""
            // 日期段
            if (this.selectedDate && this.selectedDate[0] && moment(this.selectedDate[0]).isValid()) {
                psDate = moment(this.selectedDate[0]).format("YYYY-MM-DD")
                // 时间段
                if (this.selectedTime && this.selectedTime[0] && moment(this.selectedTime[0]).isValid()) {
                    psTime = moment(this.selectedTime[0]).format("HH:mm:ss")
                } else {
                    psTime = moment(this.selectedDate[0]).format("00:00:00")
                }
            }
            if (this.selectedDate && this.selectedDate[1] && moment(this.selectedDate[1]).isValid()) {
                peDate = moment(this.selectedDate[1]).format("YYYY-MM-DD")
                // 时间段
                if (this.selectedTime && this.selectedTime[1] && moment(this.selectedTime[1]).isValid()) {
                    peTime = moment(this.selectedTime[1]).format("HH:mm:ss")
                } else {
                    peTime = moment(this.selectedDate[1]).format("23:59:59")
                }
            }
            this.listQuery.beginDate = psDate
            this.listQuery.endDate = peDate
            this.selectedDate = [this.listQuery.beginDate, this.listQuery.endDate]
            this.listQuery.beginTime = psTime
            this.listQuery.endTime = peTime
            this.selectedTime = [`${this.listQuery.beginDate} ${this.listQuery.beginTime}`, `${this.listQuery.endDate} ${this.listQuery.endTime}`]
        },
        // 加载数据
        async initLoadData() {
            this.clearAllMarkers()
            await this.getPackings()
            await this._search()
        },
        // 站点数据
        async getPackings() {
            this.parkings = []
            let area = this.$store.getters.serviceArea || ""
            await funReport.GetParkingLocations({ area }).then(response => {
                this.parkings = response
            })
            this.setPackingData()
        },
        // 设置站点信息
        setPackingData() {
            this.clearParking()
            if (this.isShowParking) {
                let markers = []
                let datas = JSON.parse(JSON.stringify(this.parkings))
                for (let i in datas) {
                    let item = datas[i]
                    let marker = new AMap.Polygon({
                        zIndex: 100,
                        bubble: true,
                        path: item.pointList,
                        strokeWeight: 1,
                        fillColor: "#1791fc", // "#f56c6c",
                        fillOpacity: 0.2,
                        strokeColor: "#1791fc", // "#f56c6c"
                        extData: item
                    })
                    // 站点点击选中
                    marker.on("click", (ev) => {
                        if (this.isMapEditState) {
                            return false
                        }
                        dataVars.parkTargets = [ev.target]
                        // 获取详情，骑入骑出数量
                        this.getParkingDetail(ev.target)
                        // 显示隐藏订单轨迹线
                        this.switchOrderLines()
                    })
                    dataVars.parkingMarker.push(marker)
                    dataVars.map.add(marker)
                }
            }
        },
        async getDataList() {
            // 清空数据
            this.orderJson = []
            this.setSelectedTime()
            window.$common.fullLoading()
            this.listQuery.area = this.$store.getters.serviceArea || ""
            await funReport.GetOrderData(this.listQuery).then(response => {
                this.orderJson = response

                let item = response.find(x => x.outService)
                console.log(item)
            })
            // 设置订单数据
            this.setPointData()
            // 设置订单轨迹线
            this.setOrderLine()
            // 清空显示/隐藏选框订单轨迹线的存储状态
            this.switchObjStates = {}
            // 判断并显示轨迹线，不同颜色
            this.switchOrderLines()
            // 更新弹出文本框数据
            this.updateTextMarkerDatas()
            // 框选状态下的拖动文本框，不能继续画框，设置为默认的false
            this.chooseNeedAutoSwitch(false)
            window.$common.closeFullLoading()
        },
        // 关锁订单点位
        setPointData() {
            this.clearLabelsLayer()
            dataVars.labelsLayer = new AMap.LabelsLayer({
                collision: false
            })
            dataVars.map.add(dataVars.labelsLayer)
            let markers = []
            let datas = JSON.parse(JSON.stringify(this.orderJson))
            for (let i in datas) {
                let item = datas[i]
                let temp0 = {
                    position: [item.startLng, item.startLat],
                    icon: {
                        type: "image",
                        image: this.iconImages[0],
                        size: [5, 5],
                        anchor: 'center'
                    }
                }
                let labelMarker0 = new AMap.LabelMarker(temp0)
                markers.push(labelMarker0)
                let temp1 = {
                    position: [item.endLng, item.endLat],
                    icon: {
                        type: "image",
                        image: this.iconImages[1],
                        size: [5, 5],
                        anchor: 'center'
                    }
                }
                let labelMarker1 = new AMap.LabelMarker(temp1)
                markers.push(labelMarker1)
            }
            // dataVars.labelsLayer.add(markers)
        },
        // 订单轨迹线
        setOrderLine() {
            this.clearOrderLine()
            let datas = JSON.parse(JSON.stringify(this.orderJson))
            for (let i in datas) {
                let item = datas[i]
                item.lineStartLng = item.startLng
                item.lineStartLat = item.startLat
                item.lineEndLng = item.endLng
                item.lineEndLat = item.endLat
                // 订单没有开始和结束位置，就排除
                if (!item.startLng || !item.startLat || !item.endLng || !item.endLat) {
                    continue
                }
                // 判断是否在站点
                if (Number(item.startParkingId)) {
                    // 开始站点
                    let curPark = this.parkings.find(x => item.startParkingId === x.id)
                    if (curPark) {
                        item.lineStartLng = curPark.centerLng
                        item.lineStartLat = curPark.centerLat
                    } else {
                        // continue
                    }
                }
                if (Number(item.endParkingId)) {
                    // 结束站点
                    let curPark = this.parkings.find(x => item.endParkingId === x.id)
                    if (curPark) {
                        item.lineEndLng = curPark.centerLng
                        item.lineEndLat = curPark.centerLat
                    } else {
                        // continue
                    }
                }
                let arr = []
                let random = Math.random() * (1 - 0.5) + 0.5
                let baseLng = 0
                let baseLat = 0
                // 结束经度 > 开始经度，结束纬度 > 开始纬度
                if (item.lineEndLng >= item.lineStartLng && item.lineEndLat > item.lineStartLat) {
                    baseLng = -0.002
                    baseLat = 0.003
                } else if (item.lineEndLng >= item.lineStartLng && item.lineEndLat < item.lineStartLat) {
                    baseLng = 0.002
                    baseLat = 0.003
                } else if (item.lineEndLng <= item.lineStartLng && item.lineEndLat < item.lineStartLat) {
                    baseLng = 0.002
                    baseLat = -0.003
                } else if (item.lineEndLng <= item.lineStartLng && item.lineEndLat > item.lineStartLat) {
                    baseLng = -0.002
                    baseLat = -0.003
                }
                let ctLng = (item.lineStartLng + item.lineEndLng) / 2 + baseLng // * random
                let ctLat = (item.lineStartLat + item.lineEndLat) / 2 + baseLat // * random
                arr = [
                    [item.lineStartLng, item.lineStartLat],
                    [ctLng, ctLat, item.lineEndLng, item.lineEndLat]
                ]
                let parkLine = new AMap.BezierCurve({
                    bubble: true,
                    path: arr,            // 设置线覆盖物路径
                    strokeColor: "", // 线颜色
                    strokeOpacity: 0.5,         // 线透明度
                    strokeWeight: 1,          // 线宽
                    strokeStyle: 'dashed',     // 线样式
                    // showDir: true,
                    extData: item
                })
                dataVars.orderLines.push(parkLine)
                dataVars.map.add(parkLine)
            }
        },
        // 获取服务器的定位path信息
        getCurrentAreaData() {
            let areaName = this.$store.getters.serviceArea || ""
            let areaList = this.$store.getters.serviceAreas || []
            let areaItem = areaList.find(x => { return x.name === areaName })
            if (areaItem) {
                this.coverMarker = areaItem || {}
                this.coverPaths = areaItem.pointList || []
            }
        },
        changeTimeType(val) {
            this.listQuery.timeType = val
            this._search()
        },
        // 时间段选择
        changeTime(arr) {
            this.cueSelectTimers = []
            if (arr) {
                this.listQuery.beginTime = moment(arr[0]).format("HH:mm:ss")
                this.listQuery.endTime = moment(arr[1]).format("HH:mm:ss")
                let sTime = moment(arr[0]).format("HH:00:00")
                let eTime = moment(arr[1]).format("HH:59:59")
                // 时间相同，配置选中效果
                if (this.listQuery.beginTime === sTime && this.listQuery.endTime === eTime) {
                    this.cueSelectTimers = [moment(arr[0]).format("H"), moment(arr[1]).format("H")]
                }
                this._search()
            }
        },
        // 快捷时间段选择
        selectTime(time) {
            this.cueSelectTimers = [time, time]
            let psTime = ""
            let peTime = ""
            let hour = time < 10 ? ("0" + time ) : time
            if (this.selectedDate && this.selectedDate[0] && moment(this.selectedDate[0]).isValid()) {
                psTime = moment(this.selectedDate[0]).format("YYYY-MM-DD " + hour + ":00:00")
            }
            if (this.selectedDate && this.selectedDate[1] && moment(this.selectedDate[1]).isValid()) {
                peTime = moment(this.selectedDate[1]).format("YYYY-MM-DD " + hour + ":59:59")
            }
            this.selectedTime = [psTime, peTime]
            this._search()
        },
        mouseSelectTime(time, type) {
            if (type === 0) {
                // 鼠标按下
                this.curMouseDownTime = time
            } else if (type === 1) {
                // 鼠标放开
                if (this.curMouseDownTime !== time) {
                    let psTime = ""
                    let peTime = ""
                    let sHour = null
                    let eHour = null
                    if (time > this.curMouseDownTime) {
                        this.cueSelectTimers = [this.curMouseDownTime, time]
                        sHour = this.curMouseDownTime < 10 ? ("0" + this.curMouseDownTime ) : this.curMouseDownTime
                        eHour = time < 10 ? ("0" + time ) : time
                    } else if (time < this.curMouseDownTime) {
                        this.cueSelectTimers = [time, this.curMouseDownTime]
                        sHour = time < 10 ? ("0" + time ) : time
                        eHour = this.curMouseDownTime < 10 ? ("0" + this.curMouseDownTime ) : this.curMouseDownTime
                    }
                    if (this.selectedDate && this.selectedDate[0] && moment(this.selectedDate[0]).isValid()) {
                        psTime = moment(this.selectedDate[0]).format("YYYY-MM-DD " + sHour + ":00:00")
                    }
                    if (this.selectedDate && this.selectedDate[1] && moment(this.selectedDate[1]).isValid()) {
                        peTime = moment(this.selectedDate[1]).format("YYYY-MM-DD " + eHour + ":59:59")
                        // peTime = moment(peTime).subtract(1, "hours")
                    }
                    this.selectedTime = [psTime, peTime]
                    this._search()
                }
            }
        },
        _search() {
            this.getDataList()
        },
        async initMap() {
            await window.$common.loadGaoDeMap({ plugins: ["AMap.MouseTool"] }, (Amap) => {
                // 生成地图
                this.initCreateMap()
                // 设置服务区
                this.setPolygonContains()
            })
        },
        // 生成地图
        initCreateMap() {
            let zoom = 16
            dataVars.map = new AMap.Map("container", {  //设置地图容器id
                viewMode: "2D",    //是否为3D地图模式
                zoom: zoom,           //初始化地图级别
                center: [104.065861,30.657401], //初始化地图中心点位置
                // scrollWheel: false
            })
            dataVars.map.addControl(new AMap.ToolBar())
            dataVars.map.addControl(new AMap.Scale())
            // 创建鼠标绘制
            this.setMouseTools()
            // 右键
            this.setContextMenu()
        },
        setContextMenu() {
            // 创建右键
            let contextMenu = new AMap.ContextMenu()
            contextMenu.addItem("<i class='el-icon-star-on'></i> 隐藏/显示轨迹", (e) => {
                this.isShowAllLine = !this.isShowAllLine
                if (this.isShowAllLine) {
                    this._search()
                } else {
                    dataVars.orderLines.forEach(ol => {
                        ol.hide()
                    })
                }
                contextMenu.close()
            })
            contextMenu.addItem("<i class='el-icon-delete'></i> 清空框选", (e) => {
                this.clearMouseTool()
                contextMenu.close()
            })
            contextMenu.addItem("<i class='el-icon-menu'></i> 矩形框选", (e) => {
                this.chooseType = this.chooseTypeArray[0]
                this.chooseSwitch(true)
                contextMenu.close()
            })
            contextMenu.addItem("<i class='el-icon-bangzhu'></i> 圆形框选", (e) => {
                this.chooseType = this.chooseTypeArray[1]
                this.chooseSwitch(true)
                contextMenu.close()
            })
            contextMenu.addItem("<i class='el-icon-lock'></i> 结束框选", (e) => {
                this.chooseSwitch(false)
                contextMenu.close()
            })
            contextMenu.addItem("<i class='el-icon-c-scale-to-original'></i> 开始/清空测距", (e) => {
                if (!dataVars.ruleTool) {
                    dataVars.ruleTool = new AMap.MouseTool(dataVars.map)
                    dataVars.ruleTool.rule()
                    this.isMapEditState = true
                } else {
                    dataVars.ruleTool.close(true)
                    dataVars.ruleTool = null
                    this.isMapEditState = false
                }
                contextMenu.close()
            })
            contextMenu.addItem("<i class='el-icon-full-screen'></i> 全屏/取消全屏", (e) => {
                this.isScreen = !this.isScreen
                contextMenu.close()
            })
            contextMenu.addItem("<i class='el-icon-switch-button'></i> 关闭右键菜单", (e) => {
                contextMenu.close()
            })
            dataVars.map.on('rightclick', (e) => {
                contextMenu.open(dataVars.map, e.lnglat)
            })
        },
        // 设置多边形数据
        setPolygonContains() {
            dataVars.polygonMap && dataVars.map.remove(dataVars.polygonMap)
            let path = this.coverPaths
            if (path.length <= 0) {
                return false
            }
            dataVars.polygonMap = new AMap.Polygon({
                bubble: true,
                path: path,
                strokeColor: "#409EFF", 
                strokeWeight: 3,
                strokeOpacity: 0.5,
                fillOpacity: 0.1,
                strokeStyle: 'dashed',
                strokeDasharray: [5, 5]
            })
            dataVars.map.add(dataVars.polygonMap)
            // 服务区有中心点则异动到服务区中心
            if (this.coverMarker && this.coverMarker.centerLng && this.coverMarker.centerLat) {
                let curLnglat = [this.coverMarker.centerLng, this.coverMarker.centerLat]
                this.moveToCenter({ lnglat: curLnglat })
            }
        },
        // 框选地图显示对应的数据
        /************鼠标绘制选点************/
        // 创建鼠标绘制
        setMouseTools() {
            dataVars.mouseTool = new AMap.MouseTool(dataVars.map)
            dataVars.mouseTool.on("draw", async (event) => {
                // 面积必须大于0
                if (event.obj.getArea && event.obj.getArea() > 0) {
                    this.setPolygons(event.obj)
                    await this.setTextMarkers(event.obj)
                    dataVars.map.remove(event.obj)
                }
            })
        },
        setPolygons(obj) {
            let polygon = null
            if (this.chooseType === this.chooseTypeArray[0]) {
                // 矩形
                polygon = new AMap.Rectangle(obj.getOptions())
            } else if (this.chooseType === this.chooseTypeArray[1]) {
                // 圆形
                polygon = new AMap.Circle(obj.getOptions())
            }
            dataVars.polygonObjs.push(polygon)
            dataVars.map.add(polygon)
            // 画框后执行轨迹加载
            this.switchOrderLines()
        },
        async setTextMarkers(obj) {
            let content = await this.getTextMarkerContent(obj)
            let curLnglat = obj.getCenter()
            let text = new AMap.Text({
                draggable: true,
                bubble: true,
                topWhenClick: true,
                text: content,
                anchor:"center",
                position: [curLnglat.lng, curLnglat.lat]
            })
            dataVars.textMarkers.push(text)
            dataVars.map.add(text)
            // 监听判断
            text.on("click", (event) => {
                let txt = event.target.dom.querySelector(".txtClose")
                let handleClick = (ev) => {
                    let isCloseClick = txt.contains(ev.target)
                    let index = dataVars.textMarkers.findIndex(x => x._amap_id === text._amap_id)
                    if (isCloseClick) {
                        dataVars.map.remove(dataVars.polygonObjs[index])
                        dataVars.polygonObjs.splice(index, 1)
                        dataVars.map.remove(text)
                        dataVars.textMarkers.splice(index, 1)
                        this.switchOrderLines()
                    } else {
                        this.showOrHideOrderLines(true, dataVars.polygonObjs[index])
                    }
                    document.removeEventListener("click", handleClick)
                }
                document.addEventListener("click", handleClick)
            })
            text.on("mousedown", (event) => {
                // 拖动开始
                if (this.isChooseOpen) {
                    this.chooseSwitch(false)
                    this.chooseNeedAutoSwitch(true)
                }
            })
            text.on("mouseup", (event) => {
                // 拖动结束
                if (this.isNeedAutoSwitch) {
                    this.chooseSwitch(true)
                    this.chooseNeedAutoSwitch(false)
                }
            })
        },
        // 获取详情，骑入骑出数量
        getParkingDetail(event) {
            if ((!event || !event.target) && dataVars.parkTargets.length <= 0) {
                return false
            }
            let obj = event.target || dataVars.parkTargets[0]
            let packItem = obj.getExtData()
            funReport.GetParkingById({ id: packItem.id }).then(res => {
                let rideInCount = 0, rideInNewUserCount = 0, rideInOldUserCount = 0
                let rideOutCount = 0, rideOutNewUserCount = 0, rideOutOldUserCount = 0
                let rideOutServiceCount = 0, rideOutSiteCount = 0, rideOutNormalCount = 0
                let rideSameCount = 0, rideSameNewUserCount = 0, rideSameOldUserCount = 0
                dataVars.orderLines.forEach((ol, i) => {
                    let orderItem = ol.getExtData()
                    let isStartContain = obj.contains([orderItem.lineStartLng, orderItem.lineStartLat])
                    let isEndContain = obj.contains([orderItem.lineEndLng, orderItem.lineEndLat])
                    if (isStartContain && isEndContain) {
                        // 骑出，骑入在一个选框区域
                        rideSameCount++
                        // 新用户，老用户数量
                        if (orderItem.isNewUserOrder) {
                            rideSameNewUserCount++
                        } else {
                            rideSameOldUserCount++
                        }
                    } else if (isStartContain) {
                        // 骑出
                        rideOutCount++
                        // 新用户，老用户数量
                        if (orderItem.isNewUserOrder) {
                            rideOutNewUserCount++
                        } else {
                            rideOutOldUserCount++
                        }
                    } else if (isEndContain) {
                        // 骑入
                        rideInCount++
                        // 新用户，老用户数量
                        if (orderItem.isNewUserOrder) {
                            rideInNewUserCount++
                        } else {
                            rideInOldUserCount++
                        }
                    }
                    // 起点在站点内
                    if (isStartContain) {
                        // 服务区外
                        if (orderItem.outService) {
                            rideOutServiceCount++
                        } else if (!Number(orderItem.endParkingId)) {
                            rideOutSiteCount++
                        } else {
                            rideOutNormalCount++
                        }
                    }
                })
                let content = `<div>
                                    <div class="txtClose" style="position: absolute; z-index: 100; top: -8px; right: -8px;"><i class="el-icon-error f20 col6"></i></div>
                                    <div class="f13" style="padding: 5px 8px 5px 5px;">
                                        <div class="mrgb5"><span class="fwb">站点名称：</span>${res.name} <i class="el-icon-document-copy col_primary"></i></div>
                                        <div class="mrgt5"><span class="fwb">起点在站点内，终点在：</div>
                                        <div class="mrgl2">--【服务区外：${rideOutServiceCount}单，站点外：${rideOutSiteCount}单，正常：${rideOutNormalCount}单】</div>
                                        <div class="mrgt5"><span class="fwb">骑入站点：</span>${rideInCount}单 <span class="col_success">(绿线)</span></div>
                                        <div class="mrgl2">--【新用户：${rideInNewUserCount}单，老用户：${rideInOldUserCount}单】</div>
                                        <div class="mrgt5"><span class="fwb">骑出站点：</span>${rideOutCount}单 <span class="col_danger">(红线)</span></div>
                                        <div class="mrgl2">--【新用户：${rideOutNewUserCount}单，老用户：${rideOutOldUserCount}单】</div>
                                        <div class="mrgt5"><span class="fwb">站内骑行：</span>${rideSameCount}单 <span class="col_info">(无轨迹线)</span></div>
                                        <div class="mrgl2">--【新用户：${rideSameNewUserCount}单，老用户：${rideSameOldUserCount}单】</div>
                                        <div class="mrgt5"><span class="fwb">合计：</span>${rideInCount + rideOutCount + rideSameCount}单</span><span class="col_info">（骑入+骑出+站内骑行）</span></div>
                                        <div class="mrgl2">--【新用户：${rideInNewUserCount + rideOutNewUserCount + rideSameNewUserCount}单，老用户：${rideInOldUserCount + rideOutOldUserCount + rideSameOldUserCount}单】</div>
                                        <div class="mrgt10 col_info">注：骑入骑出为同一站点，则不显示轨迹线。</div>
                                    </div>
                                </div>`
                let text = this.mxCommonMapTextInfo(dataVars.map, {
                    draggable: true,
                    text: content,
                    anchor: "center",
                    position: [res.centerLng, res.centerLat]
                }, () => {
                    try {
                        window.navigator.clipboard.writeText(res.name).then(() => {
                            this.successMsg("站点名称复制成功!")
                        })
                    } catch(ex) {
                        this.alert(res.name, "手动复制站点", {
                            customClass: "wordbreakba"
                        })
                    }
                }, () => {
                    dataVars.parkTargets = []
                    this.switchOrderLines()
                })
                text.on("mousedown", (event) => {
                    // 拖动开始
                    if (this.isChooseOpen) {
                        this.chooseSwitch(false)
                        this.chooseNeedAutoSwitch(true)
                    }
                })
                text.on("mouseup", (event) => {
                    console.log(this.isNeedAutoSwitch)
                    // 拖动结束
                    if (this.isNeedAutoSwitch) {
                        this.chooseSwitch(true)
                        this.chooseNeedAutoSwitch(false)
                    }
                })
            })
        },
        async getTextMarkerContent(obj, text) {
            let rideInCount = 0, rideInNewUserCount = 0, rideInOldUserCount = 0
            let rideOutCount = 0, rideOutNewUserCount = 0, rideOutOldUserCount = 0
            let rideOutServiceCount = 0, rideOutSiteCount = 0, rideOutNormalCount = 0
            let rideSameCount = 0, rideSameNewUserCount = 0, rideSameOldUserCount = 0
            dataVars.orderLines.forEach((ol, i) => {
                let orderItem = ol.getExtData()
                let isStartContain = obj.contains([orderItem.lineStartLng, orderItem.lineStartLat])
                let isEndContain = obj.contains([orderItem.lineEndLng, orderItem.lineEndLat])
                if (isStartContain && isEndContain) {
                    // 骑出，骑入在一个选框区域
                    rideSameCount++
                    // 新用户，老用户数量
                    if (orderItem.isNewUserOrder) {
                        rideSameNewUserCount++
                    } else {
                        rideSameOldUserCount++
                    }
                } else if (isStartContain) {
                    // 骑出
                    rideOutCount++
                    // 新用户，老用户数量
                    if (orderItem.isNewUserOrder) {
                        rideOutNewUserCount++
                    } else {
                        rideOutOldUserCount++
                    }
                } else if (isEndContain) {
                    // 骑入
                    rideInCount++
                    // 新用户，老用户数量
                    if (orderItem.isNewUserOrder) {
                        rideInNewUserCount++
                    } else {
                        rideInOldUserCount++
                    }
                }
                // 起点在选框内
                if (isStartContain) {
                    // 服务区外
                    if (orderItem.outService) {
                        rideOutServiceCount++
                    } else if (!Number(orderItem.endParkingId)) {
                        rideOutSiteCount++
                    } else {
                        rideOutNormalCount++
                    }
                }
            })
            let content = `<div>
                                <div class="txtClose" style="position: absolute; z-index: 100; top: -8px; right: -8px;"><i class="el-icon-error f20 col6"></i></div>
                                <div class="f13" style="padding: 5px 8px 5px 5px; ">
                                    <div><span class="fwb">起点在选框内，终点在：</div>
                                    <div class="mrgl2">--【服务区外：${rideOutServiceCount}单，站点外：${rideOutSiteCount}单，站点内：${rideOutNormalCount}单】</div>
                                    <div class="mrgt5"><span class="fwb">骑入选框：</span>${rideInCount}单 <span class="col_success">(绿线)</span></div>
                                    <div class="mrgl2">--【新用户：${rideInNewUserCount}单，老用户：${rideInOldUserCount}单】</div>
                                    <div class="mrgt5"><span class="fwb">骑出选框：</span>${rideOutCount}单 <span class="col_danger">(红线)</span></div>
                                    <div class="mrgl2">--【新用户：${rideOutNewUserCount}单，老用户：${rideOutOldUserCount}单】</div>
                                    <div class="mrgt5"><span class="fwb">框内骑行：</span>${rideSameCount}单 <span class="col_primary">(蓝线)</span></div>
                                    <div class="mrgl2">--【新用户：${rideSameNewUserCount}单，老用户：${rideSameOldUserCount}单】</div>
                                    <div class="mrgt5"><span class="fwb">合计：</span>${rideInCount + rideOutCount + rideSameCount}单<span class="col_info">（骑入+骑出+框内骑行）</span></span></div>
                                    <div class="mrgl2">--【新用户：${rideInNewUserCount + rideOutNewUserCount + rideSameNewUserCount}单，老用户：${rideInOldUserCount + rideOutOldUserCount + rideSameOldUserCount}单】</div>
                                    <div class="mrgt10 col_info">注：骑入骑出为同一站点，则不显示轨迹线。</div>
                                </div>
                            </div>`
            // 如果是刷新数据，直接更新
            if (text) {
                text.setText(content)
            }
            return content
        },
        // 共用显示隐藏订单轨迹线
        switchOrderLines() {
            // 组合所有的选框、站点后，需要进行坐标判断
            setTimeout(() => {
                let allShowLineObjs = dataVars.polygonObjs.concat(dataVars.parkTargets)
                // 更新订单轨迹
                dataVars.orderLines.forEach((ol, i) => {
                    let item = ol.getExtData()
                    let options = ol.getOptions()
                    let startIndex = -1
                    let endIndex = -1
                    // 不选择范围，则初始化
                    if (allShowLineObjs.length <= 0) {
                        this.setStrokeOptions(ol, options)
                        return
                    }
                    // 判断骑出和骑入
                    for (let k in allShowLineObjs) {
                        let obj = allShowLineObjs[k]
                        let isStartContain = obj.contains([item.lineStartLng, item.lineStartLat])
                        let isEndContain = obj.contains([item.lineEndLng, item.lineEndLat])
                        // 选框不包含骑出和骑入，则隐藏
                        if (this.switchObjStates[obj._amap_id] === false) {
                            startIndex = endIndex = -1
                            continue
                        }
                        // 骑出和骑入
                        if (isStartContain && isEndContain) {
                            startIndex = endIndex = k
                            continue
                        } else if (isStartContain) {
                            startIndex = k
                            continue
                        } else if (isEndContain) {
                            endIndex = k
                            continue
                        }
                    }
                    // 骑出和骑入都不在选框内
                    if (startIndex === -1 && endIndex === -1) {
                        ol.hide()
                    } else if (startIndex !== -1 && endIndex === -1) {
                        // 骑出
                        this.setStrokeOptions(ol, options, { color: this.lineColors[0], opacity: 0.8, style: "solid" })
                    } else if (startIndex === -1 && endIndex !== -1) {
                        // 骑入
                        this.setStrokeOptions(ol, options, { color: this.lineColors[1], opacity: 0.8, style: "solid" })
                    } else {
                        // 骑出和骑入都在选框内
                        this.setStrokeOptions(ol, options, { color: this.lineColors[2], opacity: 0.8, style: "solid" })
                    }
                })
            }, 50)
        },
        showOrHideOrderLines(isSwitch, switchObj) {
            // 在画选框的时候，不执行切换
            if (isSwitch && switchObj) {
                let curState = this.switchObjStates[switchObj._amap_id] === undefined ? true : this.switchObjStates[switchObj._amap_id]
                let switchItems = dataVars.orderLines.filter(ol => {
                    let item = ol.getExtData()
                    let result = false
                    let isStartContain = switchObj.contains([item.lineStartLng, item.lineStartLat])
                    let isEndContain = switchObj.contains([item.lineEndLng, item.lineEndLat])
                    if (isStartContain || isEndContain) {
                        result = true
                    }
                    return result
                })
                // console.log(switchItems)
                for (let i in switchItems) {
                    let ol = switchItems[i]
                    let item = ol.getExtData()
                    if (curState) {
                        ol.hide()
                    } else {
                        ol.show()
                    }
                }
                this.switchObjStates[switchObj._amap_id] = !curState
            }
        },
        // 更新弹出的文本框数据
        updateTextMarkerDatas() {
            // 更新画框弹出的文本框
            for (let i in dataVars.polygonObjs) {
                let obj = dataVars.polygonObjs[i]
                let text = dataVars.textMarkers[i]
                this.getTextMarkerContent(obj, text)
            }
            // 更新站点详情的文本框
            for (let i in dataVars.parkTargets) {
                let target = dataVars.parkTargets[i]
                this.getParkingDetail(target)
            }
        },
        setStrokeOptions(obj, options, stroke) {
            options.strokeColor = stroke && stroke.color || this.lineColors[2] // this.fillColors[Math.floor(Math.random() * this.fillColors.length)]
            options.strokeOpacity = stroke && stroke.opacity || 0.5
            options.strokeWeight = stroke && stroke.weight || 1.5
            options.strokeStyle = stroke && stroke.style || "solid"
            obj.setOptions(options)
            obj.show()
        },
        chooseSwitch(isOpen) {
            if (isOpen !== undefined) {
                this.isChooseOpen = isOpen
            } else {
                this.isChooseOpen = !this.isChooseOpen
            }
            if (!this.isChooseOpen) {
                dataVars.mouseTool && dataVars.mouseTool.close(true)
                this.isMapEditState = false
            } else {
                this.chooseSelectType(this.chooseType)
                this.isMapEditState = true
            }
        },
        chooseSelectType(type) {
            // 先移除之前的选框对象
            this.chooseType = type || this.chooseTypeArray[0]
            let options = {
                strokeColor: "#1791fc", //轮廓线颜色
                strokeOpacity: 0.3, //轮廓线透明度
                strokeWeight: 2, //轮廓线宽度
                fillColor: "#1791fc", //多边形填充颜色
                fillOpacity: 0.3, //多边形填充透明度
                strokeStyle: "solid", //线样式还支持 'dashed'
            }
            if (this.chooseType === this.chooseTypeArray[0]) {
                dataVars.mouseTool.rectangle(options)
            } else if (this.chooseType === this.chooseTypeArray[1]) {
                dataVars.mouseTool.circle(options)
            }
        },
        chooseNeedAutoSwitch(type) {
            this.isNeedAutoSwitch = type
        },
        loadKeyDown() {
            document.addEventListener("keydown", (obj) => {
                let key = obj.key
                if (key === "Control") {
                    this.chooseSwitch(true)
                }
            })
        },
        loadKeyUp() {
            document.addEventListener("keyup", (obj) => {
                let key = obj.key
                if (key === "Control") {
                    this.chooseSwitch(false)
                }
            })
        },
        clearAllMarkers() {
            this.clearLabelsLayer()
            this.clearParking()
            this.clearOrderLine()
            this.clearMouseTool()
        },
        clearLabelsLayer() {
            dataVars.labelsLayer && dataVars.map.remove(dataVars.labelsLayer)
            dataVars.labelsLayer = null
        },
        clearParking() {
            for (let i in dataVars.parkingMarker) {
                dataVars.map.remove(dataVars.parkingMarker[i])
            }
            dataVars.parkingMarker = []
        },
        clearOrderLine() {
            for (let i in dataVars.orderLines) {
                dataVars.map.remove(dataVars.orderLines[i])
            }
            dataVars.orderLines = []
        },
        clearMouseTool() {
            this.clearPolygonMarkers()
            this.clearTextMarkers()
            dataVars.mouseTool && dataVars.mouseTool.close(true)
        },
        clearPolygonMarkers() {
            for (let i in dataVars.polygonObjs) {
                dataVars.map.remove(dataVars.polygonObjs[i])
            }
            dataVars.polygonObjs = []
        },
        clearTextMarkers() {
            // 清除画框的文本框
            for (let i in dataVars.textMarkers) {
                dataVars.map.remove(dataVars.textMarkers[i])
            }
            dataVars.textMarkers = []
            // 清除站点点击的文本框
            dataVars.parkTargets = []
            this.mxCloseCommonTextInfo(dataVars.map)
        },
        moveToCenter(item) {
            dataVars.map.setCenter(item.lnglat)
        }
    }
}
</script>